.main-sec
{
    .clinic-info
    {
        margin-bottom: 60px;
        border-radius: 0 0 30px 30px;
        background-color: #333 !important;
        .info-box
        {
            margin-bottom: -60px;
            border-radius: 30px;
            .logo-box {
                img {
                    max-width: 100%;
                    max-height: 120px;
                }
            }
        }
    }
    .dr-list
    {
        list-style: none;
        margin: 0;
        padding: 0;
        li 
        {
            border-radius: 20px;
            .img-box
            {
                height: 60px;
                width: 60px;
                background-color: #eee;
                img 
                {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}